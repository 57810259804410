export default function returnFundsData() {
  return {
    title: "Преимущества KACBO",
    descr:
      "",
    list_title: "",
    list: [
      {
        text: "Быстрый и надежный результат благодаря привлечению самых современных технологий",

      },
      {
        text: "Отсутствие предоплаты. Мы получаем вознаграждение только после возврата Ваших денег",

      },
      {
        text: "Если шансов на возврат нет KACBO не будет тратить свое и Ваше время, а сразу скажет о бесперспективности дела",

      },
      {
        text: "KACBO работает абсолютно прозрачно, предоставляя клиентам всю информацию о том, какие меры предпринимаются",

      },
      {
        text: "KACBO берет на себя абсолютно всю работу. Участие клиента абсолюно минимально",

      },
    ],
    afterText: "",
  };
}

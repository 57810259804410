import React from "react";
import "../../styles/banner.css";
import "../../styles/second.css";
import bannerData from "../data/bannerData";
import LeadForm from "./LeadForm";

const Banner = () => {
  const data = bannerData();

  return (
    <section className="banner" id="home">
      <div className="bannerInner">
        <div className="container">
          <div className="gridBanner">
            <div className="leftBlock block">
              <div className="bannerLogo">
                <img src={data.logo} alt="logo" />
              </div>
              <div className="bannerText">
                <h1>{data.title}</h1>
                <h2>{data.firstBlock}</h2>
                {data.secondBlock ? (
                  <p className="banner-descr">{data.secondBlock}</p>
                ) : null}
              </div>
            </div>
            <div className="rightBlock block">
              <h3>{data.cFtitle}</h3>
              <p>{data.cFtext}</p>
              <LeadForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;

export default function whyWe() {
  return {
    title: "Уникальные инструменты возврата",
    descr:
      "Разработанное нами уникальное программное обеспечение позволяет KACBO надежно отслеживать как фиатные так и криптовалютные транзакции. А установленные надежные связи, в том числе и зарубежными, судебными, регуляторными и финансовыми организациями позволяют осуществлять возврат денег.",
    subtitle: "",
    subdescr: "Сотрудничество с самыми передовыми технологическими компаниями в сфере криптографии и блокчейн вооружило KACBO инструментом не только фиксации транзакций, но и возврата ушедшей криптовалюты",

  };
}

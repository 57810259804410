import logo from "../../images/logoNewLight.svg";
export default function slidesData() {
  return {
    title: "Вернем Ваши деньги",
    firstBlock: "Быстро и эффективно",
    secondBlock:
      "Скам-брокеры, финансовые пирамиды, казино и букмекеры, потерянная криптовалюта - в этих и множестве других случаев мы окажем Вам правовую и организационную помощь в возврате денег",
    btn: "Приступить к возврату",
    logo: logo,
    cFtitle: "Получить бесплатную консультацию",
    cFtext: "Оставьте контактные данные и мы свяжемся с Вами в ближайшее время"
  };
}
import customer1 from "../../images/customers/man3.jpg";
import customer2 from "../../images/customers/man4.jpg";
import customer3 from "../../images/customers/woman1.jpg";

export default function testimonialData() {
  return {
    title: "Отзывы о KACBO",
    desc: "",
    list: [
      {
        img: customer1,
        author: "Григорий Воронов",
        text: "По роду деятельности я часто сотрудничаю с юридическими компаниями. Поэтому потеряв деньги в инвестиционной компании я сразу же начал искать квалифицированную помощь. Хотел бы отметить профессионализм, а главное оперативность KACBO, а мне, поверьте есть с чем сравнить.",
      },

      {
        img: customer3,
        author: "Ирина Зайцева",
        text: "Как и многие другие, я выбирала инвестиционную компанию ориентируясь на внешние атрибуты успеха: респектабельный офис, серьезные бизнес-планы и прочее бла-бла-бла. Как в итоге выяснилось, все было только ширмой, за которой мошенники воровали деньги. К счастью после того как случилось страшное, я не отчаялась, а сразу начала искать способ вернуть деньги. Мне посоветовали KACBO, я обратилась, подписала договор и уже через пару недель получила назад свои деньги. Большое спасибо.",
      },
      {
        img: customer2,
        author: "Роман Дроздов",
        text: "Игры на бирже закончились лично для меня печально. И не потому что я принимал неудачные решения, просто в определенный момент, когда я захотел вывести существенную сумму со счета, его заморозили. Под разными предлогами брокер не хотел выводить мои деньги. KACBO смогли переломить ситуацию буквально за неделю. Прошло семь дней и я смог закрыть свой счет и забрать все свои деньги.",
      },
    ],
  };
}

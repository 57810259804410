import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import "./App.css";
import Banner from "./js/components/Banner";
import HowToStart from "./js/components/HowToStart";
import Testimonials from "./js/components/Testimonials";
import WhyWe from "./js/components/WhyWe";
import Benefits from "./js/components/Benefits";
import getWindowDimensions from "./js/functions/getWinSizes.js";
import { ContextProvider } from "./js/data/Context.js";
import ContactFormModal from "./js/components/modal/ContactFormModal";
import howTo from "./js/data/howToStartData";
import ret from "./js/data/returnFunds";
import whyWe from "./js/data/whyWe";
import aboutUs from "./js/data/aboutUs";
import arrow from "./images/arrowup.svg";
import WhatsAppIcon from './whatsapp/WhatsAppIcon';

const App = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const toggleModal = () => {
    setModalVisible((wasModalVisible) => !wasModalVisible);
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
      //setModalVisible(false);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const wd = windowDimensions.width;
  const hd = windowDimensions.height;
  const global = {
    wd: wd,
    hd: hd,
  };

  const topRef = useRef();
  function handleBackClick() {
    topRef.current.scrollIntoView({ behavior: "smooth" });
  }
  const [show, doShow] = useState("hidden");
  useLayoutEffect(() => {
    const onScroll = () => {
      const scrollPos = window.scrollY;      
      if (scrollPos > 100) {
        doShow("visible");
      } else {
        doShow("hidden");
      }
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);
  return (
    <ContextProvider value={global}>
      <div className="App" ref={topRef}>
        <Banner  />
        <HowToStart
          data={howTo()}
          widthItem={32}
        />
        <WhyWe data={whyWe()} />
        <Benefits  />
        <HowToStart
          
          data={ret()}
          widthItem={48}
        />
        <WhyWe data={aboutUs()} />        
        <Testimonials />
        <div className={`toTopButton ${show}`} onClick={handleBackClick}>
          <img src={arrow} alt="" />
        </div>
        <ContactFormModal
          isModalVisible={isModalVisible}
          onBackDropClick={toggleModal}
        />
      </div>
      <WhatsAppIcon />
    </ContextProvider>
  );
};

export default App;

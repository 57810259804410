import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Form, Button, Alert } from "react-bootstrap";
import imgUnCheck from "../../images/uncheck.svg";
import imgCheck from "../../images/check.svg";

const LeadForm = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  //const [sending, setSending] = useState(false);
  const [userMess, setUserMess] = useState(false);
  const [resStatus, setResStatus] = useState(false);

  const [validPhoneImg, setValidPhoneImg] = useState(null);
  const [validEmailImg, setValidEmailImg] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const keyUpHandler = () => {
    if (!phone) {
      setValidPhoneImg(null);
      return;
    }

    isValidPhoneNumber(phone)
      ? setValidPhoneImg(imgCheck)
      : setValidPhoneImg(imgUnCheck);
      
      onKeyPressHandler();
  };

  const validateEmail = (email) => {
    const regexp =
      /^([\da-z]){1}([\da-z-.+]){1,19}(@){1}([\da-z.!$%&'*+/=?^_-]{1,14})(\.){1}([a-z]{1,5})$/i;
    return regexp.test(email);
  };

  const keyUpEmailHandler = () => {
    if (!email) {
      setValidEmailImg(null);
      return;
    }

    validateEmail(email)
      ? setValidEmailImg(imgCheck)
      : setValidEmailImg(imgUnCheck);
      
      onKeyPressHandler();
  };
  
    const [typingTimer, setTypingTimer] = useState(null);    
    const doneTypingHandler = (e) => {
        const data = { name, phone, email, message };

        fetch('/log.php', {
            method: 'post',
            headers: {
                Accept: 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
    }
    
    const onKeyPressHandler = (e) => {
        if (typingTimer)
            clearTimeout(typingTimer);
        
        setTypingTimer(setTimeout(doneTypingHandler, 3000));
    }
    
  const onSubmit = (e) => {
    e.preventDefault();

    if (!phone || !name || !message) {
      setResStatus("danger");
      setUserMess(
        "Пожалуйста, проверьте введенные данные и повторите попытку"
      );

      return;
    }

    if (isValidPhoneNumber(phone) || validateEmail(email)) {
      const data = { name, phone, email, message };

      setIsSubmitting(true);
      
      fetch("/contact.php", {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((response) => {
          setIsSubmitting(false);

          if (response.status === "success") {
            //window.ym(79233721,'reachGoal','form_button');
                        window.gtag('event', 'click', {
                          'event_category': 'forma',
                          'event_label': 'click',
                          'value': 'click'
                        });      
                        window.ym(88658704,'reachGoal','form');                  
          }

          if (response.status === "success" || response.status === "danger") {
            setResStatus(response.status);
            setName("");
            setPhone("");
            setMessage("");
            setValidPhoneImg(null);
            setUserMess(response.message);
          }
        });
    } else {
      setResStatus("danger");
      setUserMess("Некорректно веден номер телефона или email!");
    }
  };

  const nameChangeHandler = (val) => {
    setName(val.target.value);
    onKeyPressHandler();
  };

  const emailChangeHandler = (val) => {
    setEmail(val.target.value);
    onKeyPressHandler();
  };

  return (
    <Form onSubmit={onSubmit} className="contactForm">
      {resStatus ? <Alert variant={resStatus}>{userMess}</Alert> : ""}
      <Form.Group controlId="formBasicUser" className="form-user form-group">
        <Form.Label>Ваше имя*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Ваше имя"
          onChange={nameChangeHandler}
          onKeyPress={onKeyPressHandler}
          value={name}
          required
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail" className="form-email form-group">
        <Form.Label>Ваш e-mail*</Form.Label>
        <Form.Control
          type="text"
          placeholder="Ваш e-mail"
          onChange={emailChangeHandler}
          onKeyUp={keyUpEmailHandler}
          onKeyPress={onKeyPressHandler}
          value={email}
          required
        />
        <img src={validEmailImg} className="imgCheckEmail" alt="" />
      </Form.Group>
      <Form.Group controlId="formBasicPhone" className="form-phone form-group">
        <Form.Label>Ваш номер телефона*</Form.Label>
        <PhoneInput
          placeholder="Ваш номер телефона"
          className="form-control"
          value={phone}
          defaultCountry={"RU"}
          international={true}
          onKeyUp={keyUpHandler}
          onKeyPress={onKeyPressHandler}
          onChange={setPhone}
          required
        />
        <img src={validPhoneImg} className="imgCheckPhone" alt="" />
      </Form.Group>
      <Form.Group className="form-group">
        <Form.Label>Ваше сообщение*</Form.Label>
        <textarea
          placeholder="Коротко опишите ваш вопрос..."
          className="form-control"
          value={message}
          onKeyUp={keyUpHandler}
          onKeyPress={onKeyPressHandler}
          onChange={(e) => setMessage(e.target.value)}
          required
        ></textarea>
      </Form.Group>

      <div className="cF-button">
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Отправка...' : 'Отправить заявку'}
        </Button>
      </div>
    </Form>
  );
};

export default LeadForm;

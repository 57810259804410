export default function abouUs() {
  return {
    title: "О KACBO",
    descr:
      "Более 10 лет мы предоставляем своим клиентам финансово-правовую и организационную помощь в возврате денег. За это время KACBO сталкивалась со всеми возможными видами мошенничества и накопила огромный опыт. В данный момент мы имеем четко отработанный и отлаженный механизм по возврату средств практически во всех возможных случаях.",
    subtitle: "",
    subdescr: "Не останавливаясь на достигнутом, последние несколько лет KACBO уделила особое внимание возврату такого популярного актива как криптовалюта. Разработанное нами программного обеспечение интегрирует криптографические модули и позволяет как отслеживать так и возвращать криптовалюту.",
    subdescr2: "Без ложной скромности мы можем заявить, что KACBO всегда была в числе лучших и остается в лидерах, уверенно шагая в будущее на острие прогресса ",
  };
}

import React, { useContext } from "react";
import "../../styles/howToStart.css";
import CommonContext from "../../js/data/Context";

const HowToStart = ({ data, widthItem }) => {
  const global = useContext(CommonContext);
  const liStyle = (i) => {
    let wd = widthItem;    
    if (global.wd <= 800) {
      wd = 48;
    }
    if (global.wd <= 450) {
      wd = 100;      
    }
    if (global.wd >= 800 && global.hd <= 450) {
      wd = 48;
    } 
    return {
      width: `${wd}%`,     
    };
  };
  return (
    <section
      className="howToStart common__section"
      id="howToStart"     
    >
      <div className="container">
        <h2>{data.title}</h2>
        <p>{data.descr}</p>
        <ul className="list">
          {data.list.map((e, i) => (
            <li key={i} style={liStyle(i)}>
              <div className="firstL">
                <div className="secondL">
                  {e.text}
                </div>
              </div>
            </li>
          ))}
        </ul>       
      </div>
    </section>
  );
};

export default HowToStart;

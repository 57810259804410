export default function whyWe() {
  const colors = ["#25c560", "#eac433"]
  return {
    title: "Как начать возврат денег?",
    descr:
      "",
    list: [
      {
        text: "Оставить заявку на сайте",
        color: colors[0],
      },
      {
        text: "Предоставить нам информацию по Вашему случаю и получить бесплатную консультацию",
        color: colors[1],
      },
      {
        text: "Заключить с KACBO договор на возврат средств",
        color: colors[0],
      },
      {
        text: "Получить назад свои деньги",
        color: colors[1],
      },
    ],
    subtitle: "",
    subdescr: "",
    cFtitle: 'Получить консультацию'
  };
}

export default function howToStartData() {
  const colors = ["#731425", "#0d0d0d"];
  return {
    title: "Что предлагает KACBO",
    descr:
      "Мы предлагаем Вам воспользоваться нашим уникальным предложением и вернуть Ваши украденные деньги. Уже течение многих лет команда KACBO работает на пересечении таких разных сфер как право, финансы и криптография, что позволяет нам возвращать деньги клиентов в самых разных случаях.",
    list_title: "Сотрудничая с нами Вы сможете получить:",
    list: [
      {
        text: "Юридическую и организационную помощь в возврате денег, chargeback или любой другой форме возврата средств",
        color: colors[0],
      },
      {
        text: "Отслеживание и возврат незаконных транзакций как внутри страны, так и в других юрисдикциях. В том числе возврат криптовалютных транзакций",
        color: colors[0],
      },
      {
        text: "Помощь во внесудебном решении дел. Если это возможно, Ваши деньги будут оперативно возвращены во внесудебном порядке",
        color: colors[1],
      },
    ],
    afterText: "",
  };
}
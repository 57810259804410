import React from "react";
import whyWe from "../data/benefits";
import "../../styles/benefits.css";
import LeadForm from "./LeadForm";

const Benefits = () => {
  const data = whyWe();

  return (
    <section
      className="benefits common__section"
      id="benefits"     
    >
      <div className="innerSection container">
        <div className="imageBlock">
          <h4>{data.cFtitle}</h4>
          <LeadForm />
        </div>
        <div className="textBlock">
          <h2>{data.title}</h2>
          <ul className="list">
            {data.list.map((e, i) => (
              <li key={i} >
                <span className="numbers">{`${i + 1}`}</span>
                <span>{e.text}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Benefits;

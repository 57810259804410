import React from "react";
import "../../styles/whyWe.css";

const WhyWe = ({data}) => {
  return (
    <section
      className="whywe common__section"
      id="whywe"
    >
      <div className="innerSection container">
        <div className="textBlock">
          {data.title ? <h2>{data.title}</h2> : null}
          {data.descr ? <p>{data.descr}</p> : null}
          {data.subdescr ? <p>{data.subdescr}</p> : null}
          {data.subdescr2 ? <p>{data.subdescr2}</p> : null}
        </div>       
      </div>
    </section>
  );
};

export default WhyWe;
